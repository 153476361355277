import { City } from "../backgorundAnimations/city/City";
import { Leaves } from "../backgorundAnimations/leaves/Leaves";
import { Stars } from "../backgorundAnimations/stars/Stars";
import "./Wallpaper.scss";
import { FunctionComponent } from "react";
interface wallpaperProps {
    selectedWallpaper: string;
    isAnimated: string;
}

export const Wallpaper: FunctionComponent<wallpaperProps> = ({ selectedWallpaper, isAnimated }) => {

    const getAnimationComponent = () => {
        switch (selectedWallpaper) {
            case 'Stars':
                return (<Stars />);
            case 'City':
                return (<City />);
            case 'Leaves':
                return (<Leaves />);

            default:
                break;
        }
    }

    return (
        <section
            className='Wallpaper'
            style={isAnimated === 'false' ? { backgroundImage: `url(${process.env.PUBLIC_URL}/assets/wallpapers/${selectedWallpaper})` } : undefined}
        >
            {
                isAnimated === 'true' ? (
                    getAnimationComponent()
                ) : (null)
            }
        </section >
    )
}