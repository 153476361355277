import "./BtnIcon.scss";
import { FunctionComponent } from "react";

interface BtnIconProps {
    iconName: string;
    onBtnClick: Function;
}

export const BtnIcon: FunctionComponent<BtnIconProps> = ({ iconName, onBtnClick }) => {
    return (
        <div className="btnicon" onClick={() => onBtnClick()}>
            <span className="icon material-symbols-outlined">{iconName}</span>
        </div>
    )
}