import { secondScreen } from "../config/secondScreen";
import { SettingProperties } from "../models/SettingProperties";

const myLocalStorage = window.localStorage || {
    _data: {},
    setItem: function (id: string, val: string) { return this._data[id] = String(val); },
    getItem: function (id: string) { return this._data.hasOwnProperty(id) ? this._data[id] : ''; },
    removeItem: function (id: string) { return delete this._data[id]; },
    clear: function () { return this._data = {}; }
};

export const SettingsService = {

    getSTBCheckInterval: () => {
        const STBCheckInterval = myLocalStorage.getItem(SettingProperties.STBCheckInterval) || secondScreen.updateInterval + '';
        return parseInt(STBCheckInterval, 10);
    },

    setSTBCheckInterval: (value: string) => {
        myLocalStorage.setItem(SettingProperties.STBCheckInterval, value);
    },

    getSelectedWallpaper: () => {
        const selectedWallpaper = myLocalStorage.getItem(SettingProperties.selectedWallpaper) || 'glacier-golden-hour-morning-i.jpg';
        return selectedWallpaper;
    },

    setSelectedWallpaper: (value: string) => {
        myLocalStorage.setItem(SettingProperties.selectedWallpaper, value);
    },

    getIsAnimatedWallpaper: () => {
        const isAnimatedWallpaper = myLocalStorage.getItem(SettingProperties.isAnimatedWallpaper) || 'false';
        return isAnimatedWallpaper;
    },

    setIsAnimatedWallpaper: (value: string) => {
        myLocalStorage.setItem(SettingProperties.isAnimatedWallpaper, value);
    }

}