import "./Home.scss";
import { FunctionComponent, useEffect, useState } from "react";
import Clock from "react-live-clock";
import { homeScreen } from "../../config/homeView";
import "moment/locale/es";
import moment from "moment";
import { BtnIcon } from "../../components/btnIcon/BtnIcon";
import { TvController } from "../../components/tvcontroller/TvController";
import { useNavigate } from "react-router-dom";
import Mute from "../../components/mute/Mute";

interface HomeProps {
    isMuted: boolean;
    muteCallback: Function;
    tvCallBack: Function;
}

export const Home: FunctionComponent<HomeProps> = ({ isMuted, muteCallback, tvCallBack }) => {
    const [isTvControllerExpanded, setIsTvControllerExpanded] = useState(false);
    const [timeZone, setTimeZone] = useState('')
    moment.locale(homeScreen.locale);
    const navigate = useNavigate();

    useEffect(() => {
        // @ts-ignore
        setTimeZone(MH.getTimeZone());
    }, []);

    const onClickSkipWUW = () => {
        // @ts-ignore
        MH.wuwActivated();
    };

    const toggleTvControllerExpanded = () => {
        if (isTvControllerExpanded) {
            document.getElementById('tvcontroller')?.classList.add('hide');
            setTimeout(() => {
                setIsTvControllerExpanded(!isTvControllerExpanded);
            }, 500);
        } else {
            document.getElementById('tvcontroller')?.classList.remove('hide');
            setIsTvControllerExpanded(!isTvControllerExpanded);
        }
    };

    const onUnmuted = () => {
        muteCallback(false);
    }

    return (
        <>
            {isMuted ? (<Mute callBack={onUnmuted} />) : (null)}
            <section className="home-container">
                {isMuted ? (null) : (
                    <div className="mute-icon">
                        <BtnIcon iconName="mic_off" onBtnClick={() => muteCallback(true)} />
                    </div>
                )}
                <h2 className="date">
                    <Clock format={homeScreen.dateFormat} timezone={timeZone} />
                </h2>
                <h1 className="time">
                    <Clock
                        format={homeScreen.timeFormat}
                        ticking={true}
                        timezone={timeZone} />
                </h1>
                <div className="pushToTalkButton">
                    <BtnIcon iconName="mic" onBtnClick={onClickSkipWUW} />
                </div>

                <div className="tv-icon">
                    <BtnIcon iconName="tv" onBtnClick={() => tvCallBack()} />
                </div>

                <div className="settings-icon">
                    <BtnIcon iconName="settings" onBtnClick={() => navigate("/settings")} />
                </div>

                <div className="show-tvcontroller-icon">
                    {isTvControllerExpanded ? (
                        <BtnIcon iconName="close" onBtnClick={toggleTvControllerExpanded} />
                    ) : (
                        <BtnIcon
                            iconName="expand_less"
                            onBtnClick={toggleTvControllerExpanded} />
                    )}
                </div>
                {isTvControllerExpanded && (<TvController />)}
            </section>
        </>
    );
};
