import { BtnIcon } from "../btnIcon/BtnIcon";
import "./TvController.scss";

export const TvController = () => {
    const onButtonClicked = (button_value: string) => {
        console.log("button clicked: " + button_value);
        //@ts-ignore
        MH.pressKey(button_value);
    };

    return (
        <div id="tvcontroller" className="tvcontroller-main-container">
            <div className="tvcontroller-container">
                <div className="row">
                    <BtnIcon
                        iconName="counter_1"
                        onBtnClick={() => onButtonClicked("1")}
                    />
                    <BtnIcon
                        iconName="counter_2"
                        onBtnClick={() => onButtonClicked("2")}
                    />
                    <BtnIcon
                        iconName="counter_3"
                        onBtnClick={() => onButtonClicked("3")}
                    />
                </div>
                <div className="row">
                    <BtnIcon
                        iconName="counter_4"
                        onBtnClick={() => onButtonClicked("4")}
                    />
                    <BtnIcon
                        iconName="counter_5"
                        onBtnClick={() => onButtonClicked("5")}
                    />
                    <BtnIcon
                        iconName="counter_6"
                        onBtnClick={() => onButtonClicked("6")}
                    />
                </div>
                <div className="row">
                    <BtnIcon
                        iconName="counter_7"
                        onBtnClick={() => onButtonClicked("7")}
                    />
                    <BtnIcon
                        iconName="counter_8"
                        onBtnClick={() => onButtonClicked("8")}
                    />
                    <BtnIcon
                        iconName="counter_9"
                        onBtnClick={() => onButtonClicked("9")}
                    />
                </div>
                <div className="row">
                    <BtnIcon iconName="home" onBtnClick={() => onButtonClicked("HOME")} />
                    <BtnIcon
                        iconName="counter_0"
                        onBtnClick={() => onButtonClicked("0")}
                    />
                    <BtnIcon iconName="sort" onBtnClick={() => onButtonClicked("EPG")} />
                </div>
                <div className="row">
                    <BtnIcon
                        iconName="fast_rewind"
                        onBtnClick={() => onButtonClicked("REW")}
                    />
                    <BtnIcon
                        iconName="play_pause"
                        onBtnClick={() => onButtonClicked("PLPAU")}
                    />
                    <BtnIcon
                        iconName="fast_forward"
                        onBtnClick={() => onButtonClicked("FWD")}
                    />
                </div>
                <div className="row">
                    <BtnIcon
                        iconName="arrow_circle_up"
                        onBtnClick={() => onButtonClicked("P+")}
                    />
                    <BtnIcon
                        iconName="expand_less"
                        onBtnClick={() => onButtonClicked("UP")}
                    />
                    <BtnIcon
                        iconName="volume_up"
                        onBtnClick={() => onButtonClicked("VOL+")}
                    />
                </div>
                <div className="row">
                    <BtnIcon
                        iconName="chevron_left"
                        onBtnClick={() => onButtonClicked("LEFT")}
                    />
                    <BtnIcon iconName="done" onBtnClick={() => onButtonClicked("OK")} />
                    <BtnIcon
                        iconName="chevron_right"
                        onBtnClick={() => onButtonClicked("RIGHT")}
                    />
                </div>
                <div className="row">
                    <BtnIcon
                        iconName="arrow_circle_down"
                        onBtnClick={() => onButtonClicked("P-")}
                    />
                    <BtnIcon
                        iconName="expand_more"
                        onBtnClick={() => onButtonClicked("DOWN")}
                    />
                    <BtnIcon
                        iconName="volume_down"
                        onBtnClick={() => onButtonClicked("VOL-")}
                    />
                </div>
                <div className="row">
                    <BtnIcon
                        iconName="reply"
                        onBtnClick={() => onButtonClicked("BACK")}
                    />

                    <BtnIcon
                        iconName="devices"
                        // @ts-ignore
                        onBtnClick={() => MH.openQuiz()}
                    />

                    <BtnIcon
                        iconName="volume_off"
                        onBtnClick={() => onButtonClicked("MUTE")}
                    />
                </div>
            </div>
        </div>
    );
};
