import "./Mute.scss";
import { FunctionComponent, useState } from "react";

interface MuteProps {
    callBack: Function;
}

const Mute: FunctionComponent<MuteProps> = ({ callBack }) => {
    const [action, setAction] = useState<string>("open");

    const onUnMuted = () => {
        setAction("hide");
        setTimeout(() => {
            callBack();
        }, 500);
    }

    return (
        <div className={`mute-container ${action}`}>
            <div className="message-container">
                <span className="mute material-symbols-outlined">mic_off</span>
                <span>Has activado el mute. Aura no puede oírte</span>
                <span className="close material-symbols-outlined" onClick={() => onUnMuted()}>close</span>
            </div>
        </div>
    )
}

export default Mute