import './App.scss';
import { Home } from './views/home/Home';
import {
    Routes,
    Route,
    useNavigate,
} from "react-router-dom";
import { Wallpaper } from './components/wallpaper/Wallpaper';
import { Settings } from './views/settings/Settings';
import { Tv } from './views/tv/Tv';
import { useEffect, useState } from 'react';
import { Recommender } from './views/recommender/Recommender';
import { SettingsService } from './services/Settings';
import { Channel } from './models/Channel';
import { Vod } from './models/Vod';
import axios from 'axios';
import { Urls } from './models/Urls';
import { SettingProperties } from './models/SettingProperties';
import { speak } from './helpers/speak';

function App() {
    const [carrouselGenre, setCarrouselGenre] = useState<string>("");
    const [carrouselFilms, setCarrouselFilms] = useState<string[]>([]);
    const [channel, setChannel] = useState<Channel | null>(null);
    const [vod, setVod] = useState<Vod | null>(null);
    const navigate = useNavigate();
    const [isMuted, setIsMuted] = useState(false);
    const [selectedWallpaper, setSelectedWallpaper] = useState<string>(SettingsService.getSelectedWallpaper());
    const [isSelectectAnimatedWallpaper, setIsSelectectAnimatedWallpaper] = useState<string>(SettingsService.getIsAnimatedWallpaper());
    const [imageData, setImageData] = useState<any>(null);

    useEffect(() => {
        changeChannelEvent();
        pushCarrouselGenreEvent();
        pushCarrouselFilmsEvent();
        vodQuestionEvent();
        vodDescribeEvent();
        watchingNow();
        navigateEvent();
        onTouch();
        vodGraphEvent();
    }, []);

    const changeChannelEvent = () => {
        window.addEventListener("changeChannel", (e) => {
            // @ts-ignore
            MH.watchingNow();
            navigate("/tv");
        });
    };

    const pushCarrouselGenreEvent = () => {
        window.addEventListener("pushCarrouselGenre", (e) => {
            // @ts-ignore
            setCarrouselGenre(e.detail);
            setCarrouselFilms([]);
            navigate("/carrousel");
        });
    };

    const pushCarrouselFilmsEvent = () => {
        window.addEventListener("pushCarrouselFilms", (e) => {
            // @ts-ignore
            var filmsArray = e.detail;
            setCarrouselFilms(filmsArray);
            setCarrouselGenre("");
            navigate("/carrousel");
        });
    };

    const vodQuestionEvent = () => {
        window.addEventListener("vodQuestion", (e) => {
            // @ts-ignore
            let data = e.detail;
            axios.post(Urls.vodQuestionURL, data,
                {
                    headers: getHeaders(),
                })
                .then((response) => {
                    speak(response.data);
                })
                .catch((error) => {
                    console.log('------------- error vodQuestionEvent: ', JSON.stringify(error, null, 2));
                });
        });
    }

    const vodGraphEvent = () => {
        window.addEventListener("vodGraph", (e) => {
            // @ts-ignore
            let data = e.detail;
            const fullUrl = `${Urls.vodGraph}?${Object.keys(data).reverse().map(key => `${key}=${data[key]}`).join('&')}`;
            axios.get(fullUrl,
                {
                    headers: graphHeaders(),
                    responseType: 'arraybuffer'
                })
                .then((response) => {
                    const byteArray = new Uint8Array(response.data);
                    const blob = new Blob([byteArray], { type: 'image/png' });
                    const imageUrl = URL.createObjectURL(blob);
                    setImageData(imageUrl);
                })
                .catch((error) => {
                    console.log('------------- error vodGraphEvent: ', JSON.stringify(error, null, 2));
                });
        });
    }

    const vodDescribeEvent = () => {
        window.addEventListener("vodDescribe", (e) => {
            console.log('----------- describe')
            // @ts-ignore
            let data = e.detail;


            axios.post(Urls.vodDescribe, data,
                {
                    headers: getHeaders(),
                })
                .then((response) => {
                    console.log('------- reponse: ', response);
                    console.log('------- reponse data: ', response.data);
                    speak(response.data);
                })
                .catch((error) => {
                    console.log('------------- error vodDescribeEvent: ', JSON.stringify(error, null, 2));
                });
        });
    }

    const graphHeaders = (): Record<string, string> => {
        return {
            'Authorization': 'Bearer ' + SettingProperties.apiKey
        }
    }

    const getHeaders = (): Record<string, string> => {
        return {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + SettingProperties.apiKey
        }
    }

    const onTouch = () => {
        window.addEventListener("touchstart", () => {
            // @ts-ignore
            MH.onTouchWebview();
        });
    }

    const watchingNow = () => {
        window.addEventListener("watchingNow", (e) => {
            resetParams();
            // @ts-ignore
            const data = e.detail;
            switch (data.type) {
                case 'vod':
                    setVod(vod => { return data });
                    break;

                case 'channel':
                    setChannel(vod => { return data });
                    break;

                case 'menu':
                    break;
            }
        });
    };

    const resetParams = () => {
        setVod(vod => { return null });
        setChannel(channel => { return null });
    }

    const navigateEvent = () => {
        window.addEventListener("navigateEvent", (e) => {
            // @ts-ignore
            navigate("/" + e.detail);
        });
    }

    const onTvCallback = () => {
        try {
            // @ts-ignore
            MH.watchingNow();
            navigate("/tv");
        } catch (error) {

        }

    }

    const onMuteCallBack = (value: boolean) => {
        // @ts-ignore
        MH.muteWuw(value);
        setIsMuted(value);
    }

    const settingsWallpaperCalBack = (selected: string, isAnimated: string) => {
        SettingsService.setSelectedWallpaper(selected);
        SettingsService.setIsAnimatedWallpaper(isAnimated);
        setSelectedWallpaper(selected);
        setIsSelectectAnimatedWallpaper(isAnimated);
    }

    const closeGraph = () => {
        setImageData(null);
    }

    return (
        <>
            <Wallpaper selectedWallpaper={selectedWallpaper} isAnimated={isSelectectAnimatedWallpaper} />
            <section className="App">
                <Routes>
                    <Route path="/" element={<Home isMuted={isMuted} muteCallback={onMuteCallBack} tvCallBack={onTvCallback} />}></Route>
                    <Route path="/settings" element={<Settings wallpaperCallBack={settingsWallpaperCalBack} />}></Route>
                    <Route path="/tv" element={<Tv channel={channel} vod={vod} imageData={imageData} closeGraph={closeGraph} />}></Route>
                    <Route path="/carrousel" element={
                        carrouselGenre !== "" ? (
                            <Recommender genre={carrouselGenre} tvCallBack={onTvCallback} />
                        ) : (
                            carrouselFilms.length > 0 ? (
                                <Recommender films={carrouselFilms} tvCallBack={onTvCallback} />
                            ) : null
                        )
                    }>
                    </Route>
                </Routes>
            </section>
        </>
    );
}

export default App;