import "./City.css";

export const City = () => {
    return (
        <div className="container"><img className="background" src={`${process.env.PUBLIC_URL}/assets/wallpapers/city.jpeg`} />
            <div className="circle-container">
                <div className="circle"></div>
            </div>
            <div className="circle-container">
                <div className="circle"></div>
            </div>
            <div className="circle-container">
                <div className="circle"></div>
            </div>
            <div className="circle-container">
                <div className="circle"></div>
            </div>
            <div className="circle-container">
                <div className="circle"></div>
            </div>
            <div className="circle-container">
                <div className="circle"></div>
            </div>
            <div className="circle-container">
                <div className="circle"></div>
            </div>
            <div className="circle-container">
                <div className="circle"></div>
            </div>
            <div className="circle-container">
                <div className="circle"></div>
            </div>
            <div className="circle-container">
                <div className="circle"></div>
            </div>
            <div className="circle-container">
                <div className="circle"></div>
            </div>
            <div className="circle-container">
                <div className="circle"></div>
            </div>
            <div className="circle-container">
                <div className="circle"></div>
            </div>
            <div className="circle-container">
                <div className="circle"></div>
            </div>
            <div className="circle-container">
                <div className="circle"></div>
            </div>
            <div className="circle-container">
                <div className="circle"></div>
            </div>
            <div className="circle-container">
                <div className="circle"></div>
            </div>
            <div className="circle-container">
                <div className="circle"></div>
            </div>
            <div className="circle-container">
                <div className="circle"></div>
            </div>
            <div className="circle-container">
                <div className="circle"></div>
            </div>
            <div className="circle-container">
                <div className="circle"></div>
            </div>
            <div className="circle-container">
                <div className="circle"></div>
            </div>
            <div className="circle-container">
                <div className="circle"></div>
            </div>
            <div className="circle-container">
                <div className="circle"></div>
            </div>
            <div className="circle-container">
                <div className="circle"></div>
            </div>
            <div className="circle-container">
                <div className="circle"></div>
            </div>
            <div className="circle-container">
                <div className="circle"></div>
            </div>
            <div className="circle-container">
                <div className="circle"></div>
            </div>
            <div className="circle-container">
                <div className="circle"></div>
            </div>
            <div className="circle-container">
                <div className="circle"></div>
            </div>
            <div className="circle-container">
                <div className="circle"></div>
            </div>
            <div className="circle-container">
                <div className="circle"></div>
            </div>
            <div className="circle-container">
                <div className="circle"></div>
            </div>
            <div className="circle-container">
                <div className="circle"></div>
            </div>
            <div className="circle-container">
                <div className="circle"></div>
            </div>
            <div className="circle-container">
                <div className="circle"></div>
            </div>
            <div className="circle-container">
                <div className="circle"></div>
            </div>
            <div className="circle-container">
                <div className="circle"></div>
            </div>
            <div className="circle-container">
                <div className="circle"></div>
            </div>
            <div className="circle-container">
                <div className="circle"></div>
            </div>
            <div className="circle-container">
                <div className="circle"></div>
            </div>
            <div className="circle-container">
                <div className="circle"></div>
            </div>
            <div className="circle-container">
                <div className="circle"></div>
            </div>
            <div className="circle-container">
                <div className="circle"></div>
            </div>
            <div className="circle-container">
                <div className="circle"></div>
            </div>
            <div className="circle-container">
                <div className="circle"></div>
            </div>
            <div className="circle-container">
                <div className="circle"></div>
            </div>
            <div className="circle-container">
                <div className="circle"></div>
            </div>
            <div className="circle-container">
                <div className="circle"></div>
            </div>
            <div className="circle-container">
                <div className="circle"></div>
            </div>
            <div className="circle-container">
                <div className="circle"></div>
            </div>
            <div className="circle-container">
                <div className="circle"></div>
            </div>
            <div className="circle-container">
                <div className="circle"></div>
            </div>
            <div className="circle-container">
                <div className="circle"></div>
            </div>
            <div className="circle-container">
                <div className="circle"></div>
            </div>
            <div className="circle-container">
                <div className="circle"></div>
            </div>
            <div className="circle-container">
                <div className="circle"></div>
            </div>
            <div className="circle-container">
                <div className="circle"></div>
            </div>
            <div className="circle-container">
                <div className="circle"></div>
            </div>
            <div className="circle-container">
                <div className="circle"></div>
            </div>
            <div className="circle-container">
                <div className="circle"></div>
            </div>
            <div className="circle-container">
                <div className="circle"></div>
            </div>
            <div className="circle-container">
                <div className="circle"></div>
            </div>
            <div className="circle-container">
                <div className="circle"></div>
            </div>
            <div className="circle-container">
                <div className="circle"></div>
            </div>
            <div className="circle-container">
                <div className="circle"></div>
            </div>
            <div className="circle-container">
                <div className="circle"></div>
            </div>
            <div className="circle-container">
                <div className="circle"></div>
            </div>
            <div className="circle-container">
                <div className="circle"></div>
            </div>
            <div className="circle-container">
                <div className="circle"></div>
            </div>
            <div className="circle-container">
                <div className="circle"></div>
            </div>
            <div className="circle-container">
                <div className="circle"></div>
            </div>
            <div className="circle-container">
                <div className="circle"></div>
            </div>
            <div className="circle-container">
                <div className="circle"></div>
            </div>
            <div className="circle-container">
                <div className="circle"></div>
            </div>
            <div className="circle-container">
                <div className="circle"></div>
            </div>
            <div className="circle-container">
                <div className="circle"></div>
            </div>
            <div className="circle-container">
                <div className="circle"></div>
            </div>
            <div className="circle-container">
                <div className="circle"></div>
            </div>
            <div className="circle-container">
                <div className="circle"></div>
            </div>
            <div className="circle-container">
                <div className="circle"></div>
            </div>
            <div className="circle-container">
                <div className="circle"></div>
            </div>
            <div className="circle-container">
                <div className="circle"></div>
            </div>
            <div className="circle-container">
                <div className="circle"></div>
            </div>
            <div className="circle-container">
                <div className="circle"></div>
            </div>
            <div className="circle-container">
                <div className="circle"></div>
            </div>
            <div className="circle-container">
                <div className="circle"></div>
            </div>
            <div className="circle-container">
                <div className="circle"></div>
            </div>
            <div className="circle-container">
                <div className="circle"></div>
            </div>
            <div className="circle-container">
                <div className="circle"></div>
            </div>
            <div className="circle-container">
                <div className="circle"></div>
            </div>
            <div className="circle-container">
                <div className="circle"></div>
            </div>
            <div className="circle-container">
                <div className="circle"></div>
            </div>
            <div className="circle-container">
                <div className="circle"></div>
            </div>
            <div className="circle-container">
                <div className="circle"></div>
            </div>
            <div className="circle-container">
                <div className="circle"></div>
            </div>
            <div className="circle-container">
                <div className="circle"></div>
            </div>
            <div className="circle-container">
                <div className="circle"></div>
            </div>
            <div className="circle-container">
                <div className="circle"></div>
            </div>
            <div className="circle-container">
                <div className="circle"></div>
            </div>
        </div>
    )
}

