import './Header.scss';
import { FunctionComponent } from "react";
import homeIcon from "../../assets/images/home.svg";
interface HeaderProps {
    clicked: Function;
}

export const Header: FunctionComponent<HeaderProps> = ({ clicked }) => {
    return (
        <header className='header'>
            <img onClick={() => clicked()} src={homeIcon} alt=''></img>
        </header>
    )
}