import axios from "axios";
import { speak } from "../../helpers/speak";
import "./Card.scss";
import { FunctionComponent, useEffect, useState } from "react";
import { GenerateResponse } from "../../models/GenerateResponse";
interface CardProps {
    data: any;
    callBack: Function;
}

export const Card: FunctionComponent<CardProps> = ({ data, callBack }) => {
    const [url, setUrl] = useState<string>('');
    const baseUrl = "https://dev.dl2discovery.org/prx/nbl/llm-api/v1/";
    const apiKey = "82913edf-7b93-44e9-a2d7-a96bdb6471d1";
    const presetId = "lmm-llava-nowdw";
    const [isHearClicked, setIsHearClicked] = useState<boolean>(false);

    useEffect(() => {
        setUrl((data.Imagenes.find((img: any) => img.id === 'ver-large').uri));
    }, []);

    const getHeaders = (): Record<string, string> => {
        if (apiKey) {
            return {
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json',
                'X-Api-Key': apiKey,
            };
        } else {
            return {
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json',
            }
        }
    }

    const getImageExplain = (url: string): Promise<any> => {
        const data = {
            sesid: "",
            preset: presetId,
            query: "describe la imagen",
            query_args: {
                media: {
                    url: url
                }
            },
        }

        return axios.post(baseUrl + "generate", data,
            {
                headers: getHeaders(),
            })
            .then(res => {
                return res.data as GenerateResponse;
            })
            .catch((error) => {
                setIsHearClicked(false);
                console.log('error: ', error);
                throw new Error('Error al realizar la solicitud');
            });
    }

    const onExplainCardClickedImage = (url: string) => {
        // speak("Estoy analizando la carátula de la película que has proporcionado. Esto puede llevar unos segundos. Por favor, ten paciencia mientras completo el análisis.").then(() => { })
        getImageExplain(url).then(res => {
            speak(res.output);
            setTimeout(() => {
                setIsHearClicked(false);
            }, 1500);
        });
        speak("Estoy analizando la carátula de la película que has proporcionado. Esto puede llevar unos segundos. Por favor, ten paciencia mientras completo el análisis.");
        setIsHearClicked(true);
    }

    return (
        <div className="carrousel-card">
            {
                data.Imagenes ? (
                    <div className="card-container">
                        <div className="shadow">
                            <img onClick={() => callBack(data.Id)} className="image-card" src={url} alt="film" />
                        </div>

                        <div className="container-iconhear">
                            {
                                isHearClicked ? (
                                    <span className="icon-hear material-symbols-outlined">pending</span>
                                ) : (
                                    <span className="icon-hear material-symbols-outlined" onClick={() => onExplainCardClickedImage(url)}>hearing</span>
                                )
                            }
                        </div>
                    </div>
                ) : (null)
            }
        </div >
    )
}
