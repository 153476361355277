import "./Stb.scss";
import { FunctionComponent, useEffect } from "react";
import { StbModel } from "../../models/Stb";

interface StbProps {
    stbFriendlyNameSelected: string;
    stb: StbModel;
}

export const Stb: FunctionComponent<StbProps> = ({ stbFriendlyNameSelected, stb }) => {

    useEffect(() => {
        // @ts-ignore
        MH.watchingNow();
    }, []);

    const selectStb = (value: string) => { }

    return (
        <div className={`card-container ${stbFriendlyNameSelected === stb.friendlyName
            ? "selected" : ""}`} onClick={() => selectStb(stb.friendlyName)}>
            <div className="card">
                <div className="body-card">
                    <span className="name">{stb.friendlyName}</span>
                </div>
            </div>
            <div className="line"></div>
        </div>
    )
}