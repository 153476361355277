import "./Settings.scss";
import { FunctionComponent, useEffect, useState } from "react";
import { SettingsService } from "../../services/Settings";
import stbConf from "../../config/stbInterval.json";
import { StbModel } from "../../models/Stb";
import { Header } from "../../components/header/Header";
import { useNavigate } from "react-router-dom";
import data from '../../assets/locales/es.json';
import { Stb } from "../../components/stb/Stb";
import wallpapers from "../../config/wallpapers.json"
import { City } from "../../components/backgorundAnimations/city/City";
import { Stars } from "../../components/backgorundAnimations/stars/Stars";
import { Leaves } from "../../components/backgorundAnimations/leaves/Leaves";

interface settinsProps {
    wallpaperCallBack: Function;
}

export const Settings: FunctionComponent<settinsProps> = ({ wallpaperCallBack }) => {
    const [action, setAction] = useState<string>("open");
    const [stbCheckInterval, setStbCheckInterval] = useState<string>(
        SettingsService.getSTBCheckInterval() + ""
    );
    const [stbsList, setStbsList] = useState<Array<StbModel>>([]);
    const [currentStbFriendlyName, setCurrentStbFriendlyName] = useState<string>('');
    const navigate = useNavigate();

    useEffect(() => {
        // @ts-ignore
        const stbs = JSON.parse(MH.getStbList());
        setStbCheckInterval(stbConf.interval[0].value.toString());
        setStbsList(stbs);
        setCurrentStbFriendlyName(stbs[0].friendlyName);
    }, []);

    const onStbClicked = () => { };

    const onSTBChangeIntervalChange = (evt: any) => {
        setStbCheckInterval(evt.target.value);
    }

    return (
        <div className={`settings-main-container ${action}`} onAnimationEnd={(event) => {
            if (event.animationName === "translateToRight") navigate("/");
        }}>
            <Header clicked={() => setAction("hide")}></Header>
            <div className="settings-container">
                <h2>{data.settingsScreen.generalTitle}</h2>
                <span className="title">{data.settingsScreen.descoLabel}</span>
                <div className="stbs-list">
                    {stbsList.map((stb: StbModel, index: number) => (
                        <Stb stbFriendlyNameSelected={currentStbFriendlyName} stb={stb}></Stb>
                    ))}
                </div>

                <div className="interval-container">
                    <span className="title">{data.settingsScreen.descoCheckInterval}</span>
                    <select
                        id="stbCheckInterval"
                        value={stbCheckInterval}
                        onChange={onSTBChangeIntervalChange}
                        className="Select">
                        {stbConf.interval.map((interval) => (
                            <option key={interval.value} value={interval.value}>
                                {interval.name}
                            </option>
                        ))}
                    </select>
                </div>

                <div className="wallpaper-container">
                    <span className="title">{data.settingsScreen.wallpaperLabel}</span>
                    <div className="wallpapers-list">
                        {
                            wallpapers.wallpapers.map((wallpaper: string) => (
                                <div className="wallpaper-card" onClick={() => wallpaperCallBack(wallpaper, 'false')}
                                    style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/assets/wallpapers/${wallpaper})` }}></div>
                            ))
                        }
                    </div>
                    <div className="wallpapers-list">
                        <div className="wallpaper-card" onClick={() => wallpaperCallBack('Stars', 'true')}>
                            <Stars />
                        </div>

                        <div className="wallpaper-card" onClick={() => wallpaperCallBack('City', 'true')}>
                            <City />
                        </div>

                        <div className="wallpaper-card" onClick={() => wallpaperCallBack('Leaves', 'true')}>
                            <Leaves />
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}


