export const normalize = (str: string) => {
    const accents = [
        { from: 'á', to: 'a' },
        { from: 'é', to: 'e' },
        { from: 'í', to: 'i' },
        { from: 'ó', to: 'o' },
        { from: 'ú', to: 'u' },
    ];

    let result = str.toLocaleLowerCase();
    accents.forEach(acc => {
        const regex = new RegExp(acc.from, 'g');
        result = result.replace(regex, acc.to);
        result = result.replace(/\s+/g, '')
    });
    return result;

}