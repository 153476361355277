/* eslint-disable array-callback-return */
import "./Tv.scss";
import { FunctionComponent, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Header } from "../../components/header/Header";
import { SettingsService } from "../../services/Settings";
import axios from "axios";
import { Channel } from "../../models/Channel";
import { Urls } from "../../models/Urls";
import { Vod } from "../../models/Vod";
import { normalize } from "../../helpers/normalice";
import { WebViews } from "../../utils/webViews";
interface TvProps {
    channel: Channel | null;
    vod: Vod | null;
    imageData: any | null;
    closeGraph: () => void;
}

export const Tv: FunctionComponent<TvProps> = ({ channel, vod, imageData, closeGraph }) => {
    const [action, setAction] = useState<string>("open");
    const [watchingNow, setWatchingNow] = useState<any | null>(null);
    const navigate = useNavigate();
    const estadioInfinitoKeys = ['futbol', 'tenis', 'deportes', 'baloncesto'];

    useEffect(() => {
        // @ts-ignore
        updateWachingNow();
    }, []);

    const updateWachingNow = () => {
        setTimeout(() => {
            // @ts-ignore
            MH.watchingNow();
            updateWachingNow();
        }, SettingsService.getSTBCheckInterval());
    }

    useEffect(() => {
        if (channel) {
            axios.get(Urls.channels)
                .then((res: any) => {
                    setWatchingNow(searchChannel(res.data));
                });
        }
    }, [channel]);

    useEffect(() => {
        if (vod) {
            axios.get(Urls.baseUrlVOD + vod.contentID + Urls.detailUrlVOD)
                .then((res: any) => {
                    setWatchingNow(res.data);
                });
        }
    }, [vod]);

    useEffect(() => {
        if (watchingNow) {
            const genre = watchingNow?.Pases[0]?.GeneroComAntena;
            if (genre) {
                estadioInfinitoKeys.map((item) => {
                    if (normalize(genre).includes(item)) {
                        // @ts-ignore
                        MH.setUrlWebview(WebViews.estadioInfinito);
                        return;
                    }
                });
            }
        }
    }, [watchingNow]);

    const searchChannel = (data: any): any => {
        const filterData = data.filter((c: any) => {
            if (c.ServiceId) {
                return c.ServiceId.toString() === channel!.channelNum.toString();
            }
            return false;

        });
        if (filterData.length) {
            if (filterData.length > 1) {
                const findData = filterData.find((c: any) => c.Nombre && c.Nombre.toLowerCase() === channel!.channelName.toLowerCase().replace(' hd', ''));
                return findData ? findData : null;
            } else {
                return filterData[0];
            }
        } else {
            return null
        }
    }

    const renderLogos = () => {
        if (channel) {
            return renderChannelLogos();
        }

        if (vod) {
            return (<img className="logo" src={watchingNow?.NivelMoral?.Logo} alt='' />);
        }
    }

    const renderChannelLogos = () => {
        return (
            <>
                <img className="logo-channel" src={watchingNow?.Logos?.find((img: any) => img.id === 'mux').uri} alt='' />
                <img className="logo" src={watchingNow?.Pases[0]?.NivelMoral?.Logo} alt='' />
            </>
        );
    }

    const renderImages = () => {
        if (channel) {
            return (<img className="image" src={watchingNow?.Pases[0]?.Imagenes?.find((img: any) => img.id === 'fanart').uri} alt='' />)
        }

        if (vod) {
            return (<img className="image" src={watchingNow?.Imagenes?.find((img: any) => img.id === 'fanart').uri} alt='' />)
        }
    }

    const renderTitles = () => {
        if (channel) {
            return (
                <>
                    <h1>{watchingNow?.Nombre}</h1>
                    <h1>{watchingNow?.Pases[0]?.Titulo}</h1>
                </>
            )
        }

        if (vod) {
            return (<h1>{watchingNow?.Titulo}</h1>)
        }
    }

    return (
        <div className={`tv-main-container ${action}`} onAnimationEnd={(event) => {
            if (event.animationName === 'translateToLeft') navigate("/")
        }}>
            <Header clicked={() => setAction("hide")}></Header>
            {
                watchingNow !== null ? (
                    <>
                        <div className="content">
                            <div className="info">
                                <div className="logos-container">
                                    {renderLogos()}
                                </div>
                                <div className="body">
                                    {renderTitles()}
                                </div>
                            </div>
                            {renderImages()}
                        </div>
                        {
                            imageData ? (
                                <div className="graph">
                                    <div className="graph-header">
                                        <span className="icon material-symbols-outlined close-graph" onClick={closeGraph}>close</span>
                                    </div>
                                    <img className="graph-image" src={imageData} />
                                </div>
                            ) : (null)
                        }
                    </>
                ) : (null)
            }
        </div>
    )
}