import "./Stars.scss";

export const Stars = () => {
    return (
        <>
            <div className="background-container">
                <img className="moon" src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/1231630/moon2.png" alt="" />
                <div className="stars"></div>
                <div className="twinkling"></div>
                <div className="clouds"></div>
            </div>
        </>
    )

}