import "./Recommender.scss";
import axios from "axios";
import { Header } from "../../components/header/Header";
import { useNavigate } from 'react-router-dom';
import { FunctionComponent, useEffect, useState } from "react";
import { Urls } from "../../models/Urls";
import { Card } from "../../components/card/Card";

interface RecommenderProps {
    genre?: string;
    films?: string[];
    tvCallBack: () => void;
}

export const Recommender: FunctionComponent<RecommenderProps> = ({ genre, films, tvCallBack }) => {
    const [content, setContent] = useState<any[]>([]);
    const [action, setAction] = useState<string>("open");
    let list: any[] = [];
    let navigate = useNavigate();

    useEffect(() => {
        getContent();
    }, [genre]);

    useEffect(() => {
        getFilms(0);
    }, [films]);

    const getContent = () => {
        axios.get(Urls.searchFilms + genre)
            .then((res) => {
                setContent(res.data.Contenidos);
            });
    };

    const getFilms = (cont: number) => {
        const title = films?.[cont].toLocaleLowerCase();
        axios.get(Urls.searchFilms + title)
            .then((res) => {
                if (res.data.Contenidos[0]) {
                    list.push(res.data.Contenidos[0]);
                }
                (cont !== films?.length! - 1) ? getFilms(cont + 1) : setContent(list);
            });
    };

    const onCardClicked = (id: number) => {
        try {
            // @ts-ignore
            MH.playVod(id.toString());
            setTimeout(() => {
                tvCallBack();
            }, 2000);
        } catch (error) {

        }
    }

    return (
        <div id="recommender"
            className={`recommender ${action}`}
            onAnimationEnd={(event) => {
                if (event.animationName === "translateToBottom") {
                    navigate("/");
                }
            }}>
            <Header clicked={() => setAction("hide")}></Header>
            <div className="carrousel-container">
                {content.map((element, index) => (
                    <Card
                        data={element.DatosEditoriales}
                        callBack={onCardClicked}
                    ></Card>
                ))}
            </div>
        </div>
    )
}